
// Do not edit directly
// Generated on Wed, 12 Mar 2025 10:30:57 GMT

$fn-border-radius-xs: .2rem;
$fn-border-radius-sm: .4rem;
$fn-border-radius-md: 1.6rem;
$fn-border-radius-full: 50%;
$border-width-sm: .1rem;
$border-width-md: .2rem;
$border-width-lg: .4rem;
$color-white: #ffffff;
$color-gray-10: #f8f9fc;
$color-gray-20: #f2f4f8;
$color-gray-30: #edeff3;
$color-gray-40: #dee2e7;
$color-gray-50: #c4cad4;
$color-gray-60: #97a2b4;
$color-gray-70: #7d88a1;
$color-gray-80: #636b88;
$color-gray-90: #454e6e;
$color-gray-100: #162741;
$color-red-20: #fde8ea;
$color-red-40: #febec4;
$color-red-60: #f25a68;
$color-red-80: #cd3745;
$color-red-100: #a51d2b;
$color-orange-20: #ffd7bd;
$color-orange-40: #fba76f;
$color-orange-60: #f16a22;
$color-orange-80: #d04d0b;
$color-orange-100: #9e3a00;
$color-yellow-20: #fff2db;
$color-yellow-40: #ffd58a;
$color-yellow-60: #ffb838;
$color-yellow-80: #e6910a;
$color-yellow-100: #985e01;
$color-green-20: #e6f9f1;
$color-green-40: #afe9d1;
$color-green-60: #1fa881;
$color-green-80: #148555;
$color-green-100: #085e38;
$color-teal-20: #d2f3f3;
$color-teal-40: #9de7e5;
$color-teal-60: #13aeac;
$color-teal-80: #14807e;
$color-teal-100: #036362;
$color-blue-20: #e0f2ff;
$color-blue-40: #9ed4fa;
$color-blue-60: #268ed9;
$color-blue-80: #0062a8;
$color-blue-100: #054d80;
$color-indigo-20: #d9dcf2;
$color-indigo-40: #97a1e7;
$color-indigo-60: #4051bf;
$color-indigo-80: #23339a;
$color-indigo-100: #0c186a;
$color-purple-20: #f4d4fc;
$color-purple-40: #d2a9e5;
$color-purple-60: #753b9d;
$color-purple-80: #521877;
$color-purple-100: #370b51;
$text-font-family-default: Open Sans, helvetica, sans-sarif;
$text-font-family-semi-bold: Open Sans SemiBold, helvetica, sans-sarif;
$text-font-family-bold: Open Sans Bold, helvetica, sans-sarif;
$text-letter-spacing-1: -.04rem;
$text-letter-spacing-2: -.02rem;
$text-letter-spacing-3: 0;
$text-letter-spacing-4: .03rem;
$text-letter-spacing-5: .06rem;
$text-line-height-xxxs: 1.6rem;
$text-line-height-xxs: 1.8rem;
$text-line-height-xs: 2rem;
$text-line-height-sm: 2.2rem;
$text-line-height-md: 2.8rem;
$text-line-height-lg: 3.2rem;
$text-line-height-xl: 4rem;
$text-line-height-base: 1.5;
$text-font-size-xxs: 1rem;
$text-font-size-xs: 1.2rem;
$text-font-size-sm: 1.4rem;
$text-font-size-md: 1.6rem;
$text-font-size-lg: 1.8rem;
$text-font-size-xl: 2.2rem;
$text-font-size-xxl: 2.6rem;
$text-font-size-xxxl: 3.2rem;
$text-font-size-base: 1.4rem; // the default font size
$screen-size-xs: 0;
$screen-size-sm: 37.5em;
$screen-size-md: 48em;
$screen-size-lg: 56.25em;
$screen-size-xl: 75em;
$spacing-2-xs: .2rem;
$spacing-xs: .4rem;
$spacing-sm: .8rem;
$spacing-md: 1.6rem;
$spacing-lg: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-xxl: 4.8rem;
$spacing-3-xl: 5.6rem;
$spacing-4-xl: 6.4rem;